<template>
  <div>
    <b-card title="Datos del pagador">
      <b-row>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="RUC"
            label-for="ruc"
          >
            <b-form-input
              id="ruc"
              v-model="ruc"
              name="ruc"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Importe de factura"
            label-for="billingAmount"
          >
            <b-form-input
              id="billingAmount"
              v-model="billingAmount"
              name="billingAmount"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Razón social"
            label-for="businessName"
          >
            <b-form-input
              id="businessName"
              v-model="businessName"
              name="businessName"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Moneda"
            label-for="currency"
          >
            <b-form-input
              id="currency"
              v-model="currency"
              name="currency"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Cliente desde"
            label-for="enrolDate"
          >
            <b-form-input
              id="enrolDate"
              v-model="enrolDate"
              name="enrolDate"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Plazo de cobro"
            label-for="collectPeriod"
          >
            <b-form-input
              id="collectPeriod"
              v-model="collectPeriod"
              name="collectPeriod"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Frecuencia de venta"
            label-for="saleFrecuency"
          >
            <b-form-input
              id="saleFrecuency"
              v-model="saleFrecuency"
              name="saleFrecuency"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Monto de ampliación"
            label-for="creditEvaluation"
          >
            <b-form-input
              id="creditEvaluation"
              v-model="billingAmountRequest"
              name="creditEvaluation"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Contacto comercial">
      <b-row>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Nombres y apellidos"
            label-for="comercialContactName"
          >
            <b-form-input
              id="comercialContactName"
              v-model="comercialContactName"
              name="comercialContactName"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Teléfono fijo"
            label-for="comercialContactTelephone"
          >
            <b-form-input
              id="comercialContactTelephone"
              v-model="comercialContactTelephone"
              name="comercialContactTelephone"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Cargo"
            label-for="comercialContactPosition"
          >
            <b-form-input
              id="comercialContactPosition"
              v-model="comercialContactPosition"
              name="comercialContactPosition"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Anexo"
            label-for="comercialContactAnnex"
          >
            <b-form-input
              id="comercialContactAnnex"
              v-model="comercialContactAnnex"
              name="comercialContactAnnex"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Celular"
            label-for="comercialContactCellphone"
          >
            <b-form-input
              id="comercialContactCellphone"
              v-model="comercialContactCellphone"
              name="comercialContactCellphone"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Contacto de Tesorería/Finanzas">
      <b-row>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Nombres y apellidos"
            label-for="financeContactName"
          >
            <b-form-input
              id="financeContactName"
              v-model="financeContactName"
              name="financeContactName"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Teléfono fijo"
            label-for="financeContactTelephone"
          >
            <b-form-input
              id="financeContactTelephone"
              v-model="financeContactTelephone"
              name="financeContactTelephone"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Cargo"
            label-for="financeContactPosition"
          >
            <b-form-input
              id="financeContactPosition"
              v-model="financeContactPosition"
              name="financeContactPosition"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Anexo"
            label-for="financeContactAnnex"
          >
            <b-form-input
              id="financeContactAnnex"
              v-model="financeContactAnnex"
              name="financeContactAnnex"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="5"
          sm="12"
        >
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="6"
            label="Celular"
            label-for="financeContactCellphone"
          >
            <b-form-input
              id="financeContactCellphone"
              v-model="financeContactCellphone"
              name="financeContactCellphone"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Descripción del producto o servicio">
      <b-row>
        <b-col
          lg="8"
          sm="12"
        >
          <b-form-textarea
            id="productDescription"
            v-model="productDescription"
            name="productDescription"
            readonly
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row
      v-if="show"
      class="justify-content-center my-2"
    >
      <b-col
        sm="6"
        md="3"
      >
        <!-- return button -->
        <b-button
          class="my-1"
          variant="outline-secondary"
          :to="{name:'Admin-creditEvalutaion-list'}"
          block
        >
          Volver
        </b-button>
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <!-- Reject button -->
        <b-button
          v-b-modal.modal-reject
          class="my-1"
          variant="secondary"
          block
        >
          Rechazar
        </b-button>
      </b-col>
      <b-col
        sm="6"
        md="3"
      >
        <!-- Approve button -->
        <b-button
          v-b-modal.modal-approve
          class="my-1"
          variant="primary"
          block
        >
          Aprobar línea
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="!show"
    >
      <b-col
        v-show="status === 'rejected'"
        sm="12"
        md="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <span>La solicitud de ampliacion ampliación ha sido rechazada.</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-show="status === 'approved'"
        sm="12"
        md="12"
      >
        <b-alert
          variant="success"
          show
        >
          <div class="alert-body">
            <span>La solicitud de ampliacion ampliación ha sido aceptada.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <!-- modal reject -->
    <b-modal
      id="modal-reject"
      centered
      title="Rechazar línea"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      ok-title="Guardar"
      @ok="handleReject"
    >
      <validation-observer ref="rejectForm">
        <b-form
          @submit.prevent="validateRejectForm"
        >
          <b-form-group
            label="Ingrese motivo de rechazo*"
            label-for="rejectMotive"
          >
            <validation-provider
              #default="{ errors }"
              name="Motivo de rechazo"
              rules="required"
              vid="rejectMotive"
            >
              <b-form-textarea
                id="rejectMotive"
                v-model="rejectMotive"
                :state="errors.length > 0 ? false:null"
                name="rejectMotive"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Guardar
        </b-button>
      </template>
    </b-modal>
    <!-- modal approve -->
    <b-modal
      id="modal-approve"
      centered
      title="Aprobar línea"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      ok-title="Guardar"
      @ok="handleApprove"
    >
      <validation-observer ref="approveForm">
        <b-form
          @submit.prevent="validateApproveForm"
        >
          <b-form-group
            label="Seleccione moneda*"
            label-for="approveCurrency"
          >
            <validation-provider
              #default="{ errors }"
              name="Moneda"
              rules="required"
              :vid="approveCurrency"
            >
              <b-form-select
                id="approveCurrency"
                v-model="approveCurrency"
                :options="optionsApproveCurrency"
                :state="errors.length > 0 ? false:null"
                name="approveCurrency"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Ingrese monto aprobado*"
            label-for="approveValue"
          >
            <validation-provider
              #default="{ errors }"
              name="Motivo de rechazo"
              rules="required|money"
              :vid="approveValue"
            >
              <b-form-input
                :id="approveValue"
                v-model="approveValue"
                :state="errors.length > 0 ? false:null"
                name="approveValue"
                :formatter="formatterMoney"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Guardar
        </b-button>
      </template>
    </b-modal>
    <!-- confirm modal -->
    <b-modal
      id="modal-multi-3"
      ref="my-modal"
      centered
      size="sm"
      title="¿Desea conservar los cambios?"
      cancel-title="Cancelar"
      ok-title="Aceptar"
      @cancel="choice=''"
      @ok="choiceConfirmed()"
    >
      <b-card-text class="my-1">
        Recuerde que esta acción no es editable
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, VBModal, BForm, BFormSelect, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    BForm,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      // menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      ruc: '',
      businessName: '',
      enrolDate: '',
      saleFrecuency: '',
      collectPeriod: '',
      currency: '',
      billingAmount: '',
      billingAmountRequest: '',
      comercialContactName: '',
      comercialContactPosition: '',
      comercialContactCellphone: '',
      comercialContactTelephone: '',
      comercialContactAnnex: '',
      financeContactName: '',
      financeContactPosition: '',
      financeContactCellphone: '',
      financeContactTelephone: '',
      financeContactAnnex: '',
      productDescription: '',
      status: '',
      payerStatus: '',
      rejectMotive: '',
      payerId: '',
      approveCurrency: 'Soles',
      approveValue: '',
      optionsApproveCurrency: [
        { value: 'Soles', text: 'Soles (S/)' },
        { value: 'Dolares', text: 'Dólares $)' },
      ],
      show: true,
      choice: '',
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    const creditEvaluationId = this.$route.params.id
    useJwt.getCreditEvaluation({
      email: this.userEmail,
      creditEvaluationId,
    }).then(res => {
      this.ruc = res.data.creditEvaluation.payer.ruc
      this.businessName = res.data.creditEvaluation.payer.businessName
      this.enrolDate = res.data.creditEvaluation.payer.meetingDate
      this.saleFrecuency = res.data.creditEvaluation.saleFrecuency
      this.collectPeriod = res.data.creditEvaluation.payer.collectPeriod
      this.currency = res.data.creditEvaluation.currency
      this.billingAmount = res.data.creditEvaluation.payer.billingAmount
      this.comercialContactName = res.data.creditEvaluation.comercialContactName
      this.comercialContactPosition = res.data.creditEvaluation.comercialContactPosition
      this.comercialContactCellphone = res.data.creditEvaluation.comercialContactCellphone
      this.comercialContactTelephone = res.data.creditEvaluation.comercialContactTelephone
      this.comercialContactAnnex = res.data.creditEvaluation.comercialContactAnnex
      this.financeContactName = res.data.creditEvaluation.financeContactName
      this.financeContactPosition = res.data.creditEvaluation.financeContactPosition
      this.financeContactCellphone = res.data.creditEvaluation.financeContactCellphone
      this.financeContactTelephone = res.data.creditEvaluation.financeContactTelephone
      this.financeContactAnnex = res.data.creditEvaluation.financeContactAnnex
      this.productDescription = res.data.creditEvaluation.productDescription
      this.status = res.data.creditEvaluation.status
      this.payerStatus = res.data.creditEvaluation.payer.status
      this.billingAmountRequest = res.data.creditEvaluation.billingAmount
      this.payerId = res.data.creditEvaluation.payer._id
      this.show = (this.payerStatus === 'approved' && this.status === 'notApproved')
    })
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    formatterMoney(value) {
      return value.replace(/[^0-9.]/g, '')
    },
    handleReject(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validateRejectForm()
    },
    handleApprove(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validateApproveForm()
    },
    choiceConfirmed() {
      const creditEvaluationId = this.$route.params.id
      if (this.choice === 'reject') {
        useJwt.updateCreditEvaluation({
          email: this.userEmail,
          creditEvaluationId,
          payerId: this.payerId,
          action: 'reject',
          data: {
            rejectMotive: this.rejectMotive,
          },
        })
          .then(() => {
            this.$router.replace('/admin/lista-ampliacion-crediticia')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Éxito',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Se ha rechazado la solicitud',
                  },
                },
                {
                  timeout: 8000,
                })
              })
          })
          .catch(error => {
            console.log('errores', error.response.data.error)
            this.$refs.personalDataForm.setErrors(error.response.data.error)
          })
      } else if (this.choice === 'approve') {
        useJwt.updateCreditEvaluation({
          email: this.userEmail,
          creditEvaluationId,
          payerId: this.payerId,
          action: 'approve',
          data: {
            approveCurrency: this.approveCurrency,
            approveValue: this.approveValue,
          },
        })
          .then(() => {
            this.$router.replace('/admin/lista-ampliacion-crediticia')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Éxito',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Se ha aprobado la solicitud',
                  },
                },
                {
                  timeout: 8000,
                })
              })
          })
          .catch(error => {
            console.log('errores', error.response.data.error)
            this.$refs.personalDataForm.setErrors(error.response.data.error)
          })
      }
    },
    validateRejectForm() {
      this.$refs.rejectForm.validate().then(success => {
        if (success) {
          this.choice = 'reject'
          this.$refs['my-modal'].show()
        }
      })
    },
    validateApproveForm() {
      this.$refs.approveForm.validate().then(success => {
        if (success) {
          this.choice = 'approve'
          this.$refs['my-modal'].show()
        }
      })
    },
  },
}
</script>
